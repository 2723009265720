import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { ReactNode } from "react";

import { useAuth } from "@m/login";

interface Props {
  title: string;
  content: ReactNode;
  action?: ReactNode;
}

export const ErrorPage = ({ title, content, action = null }: Props) => {
  const { isAuthenticated } = useAuth();

  return (
    <div className="my-5 flex max-w-screen-md flex-col items-center gap-5">
      <ExclamationCircleIcon className="h-[100px] rounded-full border-[3px] p-3 text-action" />
      <div className="flex flex-col gap-3 text-center">
        <div
          className={clsx("font-semibold text-default", {
            "text-2xl": isAuthenticated,
            "text-4xl": !isAuthenticated,
          })}
        >
          {title}
        </div>
        <div
          className={clsx("font-normal text-subdued", {
            "text-lg": isAuthenticated,
            "text-xl": !isAuthenticated,
          })}
        >
          {content}
        </div>
      </div>
      {action}
    </div>
  );
};
