import { Outlet } from "react-router-dom";

import { PATHS } from "@mc/constants";
import { useMarketplaceRegistrations } from "@mc/features/Onboarding";
import { FullScreenLayout } from "@mc/layouts";
import { Navigate } from "@mc/router";

/**
 * Renders child routes, if the user has a pending onboarding,
 * otherwise redirects to root.
 */
export const OnboardingOutlet = () => {
  const { hasStartedRegistrations } = useMarketplaceRegistrations();

  if (!hasStartedRegistrations) return <Navigate replace to={PATHS.ROOT} />;

  return (
    <FullScreenLayout>
      <Outlet />
    </FullScreenLayout>
  );
};
