export const RELEASE_NOTES_URL =
  "https://resources.missioncloud.com/en/collections/4063386-release-notes";
export const KNOWLEDGE_BASE_URL =
  "https://resources.missioncloud.com/en/collections/8050735-help-articles";
export const MARKETPLACE_KNOWLEDGE_BASE_URL =
  "https://resources.missioncloud.com/en/collections/8857725-help-articles";

export const CONTACT_US_URL = "mailto:product@missioncloud.com";
export const SSO_SESSION_URL =
  "mailto:support@missioncloud.com?subject=I'd like to enable Single Sign-On in Mission Control";

export const AWS_CONSOLE_URL = "https://console.aws.amazon.com/";
export const AWS_CONSOLE_SIGN_IN_URL = (awsAccountId: string) =>
  `https://${awsAccountId}.signin.aws.amazon.com/console`;
