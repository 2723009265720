import { Float, FloatProps } from "@headlessui-float/react";
import { Combobox } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { ChangeEventHandler, FC } from "react";

interface SearchProps {
  ariaLabel?: string;
  disabled?: boolean;
  dropdownDirection?: FloatProps["placement"];
  dropdownOptions?: string[];
  handleSearchInputChange: ChangeEventHandler;
  handleSubmitSearch: (searchTerm: string) => void;
  maxDropdownOptions?: number;
  placeholder?: string;
  searchTerm: string;
}

export const Search: FC<SearchProps> = ({
  ariaLabel = "Search",
  disabled = false,
  dropdownDirection = "bottom-start",
  dropdownOptions = [],
  handleSearchInputChange,
  handleSubmitSearch,
  maxDropdownOptions = 3,
  placeholder = "Search",
  searchTerm = "",
}) => {
  const filteredOptions = dropdownOptions
    .filter((option) => option.toLowerCase().includes(searchTerm.toLowerCase()))
    .sort((a, b) => a.localeCompare(b, "en"))
    .slice(0, maxDropdownOptions);

  const handleClear = () => {
    handleSubmitSearch("");
  };

  return (
    <Combobox
      onChange={handleSubmitSearch}
      value={searchTerm}
      disabled={disabled}
    >
      {({ open, activeIndex }) => {
        const showOptions =
          searchTerm.length < MINIMUM_SEARCH_LENGTH ? false : open;
        return (
          <Float
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            placement={dropdownDirection}
            offset={8}
            show={showOptions}
          >
            <label
              className={clsx(
                "flex cursor-text flex-row-reverse items-center gap-0.5 rounded-lg border border-form-border px-1 py-1 text-default focus-within:border-layout-active",
                {
                  "bg-neutral-50": disabled,
                }
              )}
            >
              <div className="cursor-pointer" onClick={handleClear}>
                <XMarkIcon
                  className={clsx("h-2 w-2", {
                    "cursor-text opacity-0": !searchTerm,
                  })}
                />
              </div>
              <Combobox.Input
                aria-label={ariaLabel}
                autoFocus
                className={clsx(
                  "focus:shadow-none min-w-[256px] border-0 bg-none p-0 text-sm placeholder-neutral-400 focus:outline-none focus:ring-0",
                  {
                    "cursor-not-allowed": disabled,
                  }
                )}
                onChange={handleSearchInputChange}
                placeholder={placeholder}
                displayValue={() => searchTerm}
              />
              <MagnifyingGlassIcon
                aria-label="Search Icon"
                className="inline h-2.5 w-2.5 text-neutral-400"
              />
            </label>
            <div aria-label="Search Options">
              {showOptions && (
                <Combobox.Options
                  className="min-w-[256px] cursor-pointer rounded-md border bg-white p-1 text-sm text-default outline-none drop-shadow"
                  static={true}
                >
                  {searchTerm.length >= MINIMUM_SEARCH_LENGTH && (
                    <Combobox.Option
                      className={clsx("p-1", {
                        "rounded-md bg-gray-100": activeIndex === 0,
                      })}
                      value={searchTerm}
                    >
                      Search "{searchTerm}"
                    </Combobox.Option>
                  )}
                  {filteredOptions.map((option, i) => (
                    <Combobox.Option
                      className={clsx("p-1", {
                        "rounded-md bg-gray-100": activeIndex === i + 1,
                      })}
                      key={option}
                      value={option}
                    >
                      {option}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              )}
            </div>
          </Float>
        );
      }}
    </Combobox>
  );
};

export const MINIMUM_SEARCH_LENGTH = 3;
