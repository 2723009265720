import { useMarketplaceRegistrations } from "@mc/features/Onboarding";

import { MarketplaceSubscriptionsEmpty } from "./MarketplaceSubscriptionsEmpty";
import { SubscriptionDetailsCard } from "./SubscriptionDetailsCard";

export const MarketplaceSubscriptionCards = () => {
  const { onboardedRegistrations, hasOnboardedRegistrations } =
    useMarketplaceRegistrations();

  if (!hasOnboardedRegistrations) return <MarketplaceSubscriptionsEmpty />;

  return (
    <div className="grid grid-cols-1 gap-3 lg:grid-cols-2">
      {onboardedRegistrations.map((subscription) => {
        const {
          awsAccountId,
          catalogItem: { displayName },
          expirationTime,
          iamExternalId,
          id,
          registrationStatus,
          subscribeTime,
        } = subscription;

        return (
          <SubscriptionDetailsCard
            key={id}
            expiresAt={expirationTime}
            iamExternalId={iamExternalId}
            registrationStatus={registrationStatus}
            productDisplayName={displayName}
            subscribedAccountId={awsAccountId}
            subscribedAt={subscribeTime}
          />
        );
      })}
    </div>
  );
};
