import { AccessRequired } from "@mc/components/AccessRequired";
import { SidebarGroup, SidebarLink } from "@mc/components/Sidebar/components";
import { FEATURE_FLAGS, MUTATIONS, PATHS } from "@mc/constants";

export const SettingsNavigation = () => {
  return (
    <nav className="flex flex-col gap-3">
      <SidebarGroup title="My Account">
        <SidebarLink title="Profile" to={PATHS.SETTINGS_PROFILE} />
        <SidebarLink title="Notifications" to={PATHS.SETTINGS_NOTIFICATIONS} />
      </SidebarGroup>

      <SidebarGroup title="Company">
        <SidebarLink title="Users" to={PATHS.SETTINGS_USERS} />
        <SidebarLink title="Accounts" to={PATHS.SETTINGS_ACCOUNTS} />
        <SidebarLink title="Subscriptions" to={PATHS.SETTINGS_SUBSCRIPTIONS} />
        <AccessRequired mutation={MUTATIONS.ENFORCE_MFA}>
          <SidebarLink title="Security" to={PATHS.SETTINGS_SECURITY} />
        </AccessRequired>
        <AccessRequired
          feature={FEATURE_FLAGS.BILLING_ALLOW_CREDIT_CARD_PAYMENT}
          mutation={MUTATIONS.UPDATE_PAYMENT_PROFILE}
        >
          <SidebarLink title="Billing" to={PATHS.SETTINGS_BILLING} />
        </AccessRequired>
        <AccessRequired feature={FEATURE_FLAGS.SETTINGS_ROLLOUT_BOOKMARKS_PAGE}>
          <SidebarLink title="Bookmarks" to={PATHS.SETTINGS_BOOKMARKS} />
        </AccessRequired>
      </SidebarGroup>
    </nav>
  );
};
