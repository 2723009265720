import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { MarketplaceRegistrationStatus } from "@m/api/public/types";
import { SpinnerScreen } from "@m/ui";

import { Page } from "@mc/components/Page";
import { useMarketplaceRegistrations } from "@mc/features/Onboarding";

import { useStartOnboarding, useValidateRegistrationToken } from "../api";
import {
  SubscriptionAlreadyConfiguredError,
  SubscriptionDoesNotExistError,
} from "../components";

export const AuthenticatedSignupPage = () => (
  <Page
    className="flex h-screen items-center justify-center"
    data-testid="authenticated-signup-page"
    padding={false}
  >
    <AuthenticatedSignupPageContent />
  </Page>
);

const AuthenticatedSignupPageContent = () => {
  const { token } = useParams<{ token: string }>();

  const { newRegistrations } = useMarketplaceRegistrations();
  const registration = newRegistrations?.[0]; // todo(djbowers): what if there are multiple new registrations?

  const { data: tokenValidationResponse, error: tokenValidationError } =
    useValidateRegistrationToken(token);

  const [startOnboarding, { called: startOnboardingCalled }] =
    useStartOnboarding();

  const isTokenValid = tokenValidationResponse?.success;
  const productName = tokenValidationResponse?.productName;
  const registrationStatus = tokenValidationResponse?.registrationStatus;

  useEffect(() => {
    if (isTokenValid && registration && !startOnboardingCalled)
      startOnboarding(
        registration.customerIdentifier,
        registration.marketplaceProductCode
      );
  }, [isTokenValid, startOnboarding, registration, startOnboardingCalled]);

  if (registrationStatus === MarketplaceRegistrationStatus.Active) {
    return <SubscriptionAlreadyConfiguredError productName={productName} />;
  }
  if (tokenValidationError) {
    return <SubscriptionDoesNotExistError />;
  }
  return <SpinnerScreen fitToParent />;
};
