import { CaseCategory } from "@m/api/public/types";

export const isLiveChatEnabled = (category: CaseCategory) => {
  switch (category) {
    case CaseCategory.Alerts:
    case CaseCategory.Aws:
    case CaseCategory.Consulting:
    case CaseCategory.Empty:
    case CaseCategory.Incident:
    case CaseCategory.Maintenance:
    case CaseCategory.ProblemManagement:
    case CaseCategory.ProcessChange:
    case CaseCategory.Reporting:
    case CaseCategory.Request:
      return true;
    case CaseCategory.Admin:
    case CaseCategory.Billing:
    case CaseCategory.CmpPool:
    case CaseCategory.JunkSpam:
    case CaseCategory.Moderna:
    case CaseCategory.DevopsDesk:
      return false;
    default:
      return true;
  }
};
