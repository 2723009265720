import { useEffect, useMemo, useState } from "react";
import {
  ArrayParam,
  DateParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import {
  AggregateBillingPeriod,
  MarketplaceDimension,
} from "@m/api/public/types";
import {
  Button,
  FilterBar,
  FilterSelect,
  Table,
  Tabs,
  createFilterOptions,
} from "@m/ui";
import { dt, formatFullDate, toProperCase } from "@m/utils";

import { PageHeading } from "@mc/components/PageHeading";
import { useCSVDownload } from "@mc/hooks/useCSVDownload";

import {
  GET_AGGREGATED_METERED_CHARGES_REPORT,
  GET_METERED_CHARGES_TRANSACTIONS_REPORT,
  useAggregatedMeteredCharges,
  useCompanyProductsDimensions,
  useMeteredCharges,
} from "../api";
import {
  MeteredChargeDateTooltip,
  MeteredChargesDatePicker,
} from "../components";

type QueryParamsType = {
  products: string[];
  dimensionCodes: string[];
  chargeDateStart?: Date | null;
  chargeDateEnd?: Date | null;
  reportedDateStart?: Date | null;
  reportedDateEnd?: Date | null;
  period: AggregateBillingPeriod;
};

enum ChargeDateType {
  Reported = "REPORTED",
  Charged = "CHARGED",
}

export const MeteredChargesTable = () => {
  const [query, setQuery] = useQueryParams({
    products: withDefault(ArrayParam, []),
    dimensionCodes: withDefault(ArrayParam, []),
    chargeDateStart: withDefault(DateParam, null),
    chargeDateEnd: withDefault(DateParam, null),
    reportedDateStart: withDefault(DateParam, null),
    reportedDateEnd: withDefault(DateParam, null),
    period: withDefault(StringParam, null),
  });

  const {
    data: { products, dimensions },
    loading,
  } = useCompanyProductsDimensions();

  const handleQueryChange = (query) => {
    setQuery(query);
  };

  const selectedMeteredChargesTable =
    query.period || AggregateBillingPeriod.Daily;

  return (
    <div
      data-testid={`metered-charges-${selectedMeteredChargesTable.toLowerCase()}`}
      className="mt-4 flex flex-col"
    >
      {selectedMeteredChargesTable === AggregateBillingPeriod.Hourly && (
        <MeteredChargesTableHourly
          query={query}
          dimensions={dimensions}
          products={products}
          loading={loading}
          handleQueryChange={handleQueryChange}
        />
      )}
      {(selectedMeteredChargesTable === AggregateBillingPeriod.Daily ||
        selectedMeteredChargesTable === AggregateBillingPeriod.Monthly) && (
        <AggregatedMeteredChargesTable
          query={query}
          billingPeriodType={selectedMeteredChargesTable}
          dimensions={dimensions}
          products={products}
          loading={loading}
          handleQueryChange={handleQueryChange}
        />
      )}
    </div>
  );
};

export const MeteredChargesTableHourly = ({
  query,
  dimensions,
  products,
  loading,
  handleQueryChange,
}: {
  query: QueryParamsType;
  dimensions: Pick<MarketplaceDimension, "displayName" | "dimensionTypeId">[];
  products: string[];
  loading: boolean;
  handleQueryChange: (query: QueryParamsType) => void;
}) => {
  const {
    data: { meteredCharges },
    pagination,
    loading: chargesLoading,
  } = useMeteredCharges(query);

  const rows = useMemo(() => {
    const formatBillingPeriod = (isoDate) => {
      const shouldIncludeYear = dt.now().year !== dt.fromISO(isoDate).year;
      return (
        <>
          {formatFullDate(
            isoDate,
            `MMMM d${shouldIncludeYear ? ", yyyy" : ""} `
          ) || "—"}
          <span className="font-thin text-slate-400">
            - {formatFullDate(isoDate, "h a")}
          </span>
        </>
      );
    };

    const createDateTooltip = (isPending, timestamp) => (
      <MeteredChargeDateTooltip isPending={isPending} timestamp={timestamp} />
    );

    return meteredCharges.map((meteredCharge) => ({
      productName: meteredCharge?.catalogItem?.displayName || "—",
      billingPeriod: formatBillingPeriod(
        meteredCharge?.billingPeriod?.isoformat
      ),
      dimension: meteredCharge?.dimension?.displayName || "—",
      meteredUnits: meteredCharge?.quantity,
      reportedDate: createDateTooltip(false, meteredCharge?.reportedDate),
      chargeDate: createDateTooltip(
        !meteredCharge?.successfullySent,
        meteredCharge?.chargeDate
      ),
    }));
  }, [meteredCharges]);

  return (
    <>
      <div className="flex w-full">
        <PageHeading heading="Transactions" />
        <ExportButton
          query={query}
          billingPeriodType={AggregateBillingPeriod.Hourly}
        />
      </div>
      <MeteredChargesTableFilter
        query={query}
        setQuery={handleQueryChange}
        dimensions={dimensions}
        eligibleProducts={products}
        selectedMeteredChargesTable={AggregateBillingPeriod.Hourly}
        handleQueryChange={handleQueryChange}
        loading={loading}
      />
      <Table
        headerFontSize="small"
        headers={TABLE_HEADERS}
        loading={loading && chargesLoading}
        rows={rows}
        emptyMessage="No transactions found"
        {...pagination}
      />
    </>
  );
};

export const AggregatedMeteredChargesTable = ({
  query,
  billingPeriodType,
  dimensions,
  products,
  loading,
  handleQueryChange,
}: {
  query: QueryParamsType;
  billingPeriodType: AggregateBillingPeriod;
  dimensions: Pick<MarketplaceDimension, "displayName" | "dimensionTypeId">[];
  products: string[];
  loading: boolean;
  handleQueryChange: (query: QueryParamsType) => void;
}) => {
  const {
    data: { aggregatedMeteredCharges, productNameBySignupToken },
    pagination,
    loading: chargesLoading,
  } = useAggregatedMeteredCharges({
    query,
    billingPeriodType,
  });

  const dimensionsIdNameMap = useMemo(() => {
    const map = new Map();
    dimensions.forEach((dimension) => {
      map.set(dimension.dimensionTypeId, dimension.displayName);
    });
    return map;
  }, [dimensions]);

  const rows = useMemo(() => {
    const formatBillingPeriod = (meteredCharge) => {
      const isBillingPeriodDefined =
        meteredCharge?.month && meteredCharge?.year;

      if (!isBillingPeriodDefined) return "—";

      const dateFormat =
        billingPeriodType === AggregateBillingPeriod.Daily ? "LLLL d" : "LLLL";
      return dt
        .fromObject({
          day: meteredCharge?.day,
          month: meteredCharge?.month,
          year: meteredCharge?.year,
        })
        .toFormat(
          `${dateFormat}${
            dt.now().year !== meteredCharge?.year ? ", yyyy" : ""
          }`
        );
    };

    const getProductName = (meteredCharge) =>
      productNameBySignupToken.get(
        meteredCharge?.marketplaceRegistrationSignupToken
      ) || "—";

    const getDimension = (meteredCharge) =>
      dimensionsIdNameMap.get(meteredCharge?.dimensionTypeId) || "—";

    return aggregatedMeteredCharges.map((meteredCharge) => ({
      productName: getProductName(meteredCharge),
      billingPeriod: formatBillingPeriod(meteredCharge),
      dimension: getDimension(meteredCharge),
      meteredUnits: meteredCharge?.meteredQuantity,
    }));
  }, [
    aggregatedMeteredCharges,
    dimensionsIdNameMap,
    billingPeriodType,
    productNameBySignupToken,
  ]);
  return (
    <>
      <div className="flex w-full">
        <PageHeading heading="Transactions" />
        <ExportButton query={query} billingPeriodType={billingPeriodType} />
      </div>
      <MeteredChargesTableFilter
        query={query}
        setQuery={handleQueryChange}
        dimensions={dimensions}
        eligibleProducts={products}
        selectedMeteredChargesTable={billingPeriodType}
        handleQueryChange={handleQueryChange}
        loading={loading && chargesLoading}
      />
      <Table
        headerFontSize="small"
        headers={AGGREGATED_TABLE_HEADERS}
        loading={loading}
        rows={rows}
        emptyMessage="No transactions found"
        {...pagination}
      />
    </>
  );
};

export const MeteredChargesTableFilter = ({
  query,
  setQuery,
  eligibleProducts,
  dimensions,
  selectedMeteredChargesTable,
  handleQueryChange,
  loading,
}: {
  query: QueryParamsType;
  setQuery: (query: QueryParamsType) => void;
  eligibleProducts: string[];
  dimensions: Pick<MarketplaceDimension, "displayName" | "dimensionTypeId">[];
  selectedMeteredChargesTable: AggregateBillingPeriod;
  handleQueryChange: ({
    products,
    dimensionCodes,
    chargeDateStart,
    chargeDateEnd,
    reportedDateStart,
    reportedDateEnd,
  }: QueryParamsType) => void;
  loading: boolean;
}) => {
  const dimensionOptions = useMemo(
    () =>
      dimensions.map((dimension) => ({
        id: dimension.dimensionTypeId,
        label: dimension.displayName,
      })),
    [dimensions]
  );

  const handleSelectProductFilters = (products: string[]) => {
    handleQueryChange({ ...query, products });
  };

  const handleSelectDimensionsFilters = (dimensionCodes: string[]) => {
    handleQueryChange({ ...query, dimensionCodes });
  };

  const handleChargeDateChange = (
    dateType: ChargeDateType,
    [newStartDate, newEndDate]: [Date | null, Date | null]
  ) => {
    const startDateKey =
      dateType === ChargeDateType.Charged
        ? "chargeDateStart"
        : "reportedDateStart";
    const endDateKey =
      dateType === ChargeDateType.Charged ? "chargeDateEnd" : "reportedDateEnd";

    // When the monthly table is selected, start date should be the start of selected month and end date the end of select month
    const isMonthly =
      selectedMeteredChargesTable === AggregateBillingPeriod.Monthly;

    const startDate = isMonthly
      ? dt.fromJSDate(newStartDate).startOf("day").startOf("month").toJSDate()
      : dt.fromJSDate(newStartDate).startOf("day").toJSDate();

    const endDate = isMonthly
      ? dt.fromJSDate(newEndDate).endOf("day").endOf("month").toJSDate()
      : dt.fromJSDate(newEndDate).endOf("day").toJSDate();

    setQuery({
      ...query,
      [startDateKey]: startDate ?? undefined,
      [endDateKey]: endDate ?? undefined,
    });
  };

  const handleResetFilters = () => {
    handleQueryChange({
      products: [],
      dimensionCodes: [],
      chargeDateStart: null,
      chargeDateEnd: null,
      reportedDateStart: null,
      reportedDateEnd: null,
      period: query.period, // We do not want to reset the currently viewed billing period table
    });
  };

  const handleTableChange = (period: AggregateBillingPeriod) => {
    handleQueryChange({
      ...query,
      period: period
        ? (period.toUpperCase() as AggregateBillingPeriod)
        : undefined,
    });
  };

  const {
    products: selectedProducts,
    dimensionCodes: selectedDimensions,
    chargeDateStart,
    chargeDateEnd,
    reportedDateStart,
    reportedDateEnd,
  } = query;

  return (
    <div className="mb-2 flex w-full flex-wrap items-center">
      <FilterBar isActive={true} onResetFilters={handleResetFilters}>
        <MeteredChargesDatePicker
          billingPeriodType={selectedMeteredChargesTable}
          label="Charge Date"
          onChange={(dates) =>
            handleChargeDateChange(ChargeDateType.Charged, dates)
          }
          startDate={chargeDateStart}
          endDate={chargeDateEnd}
        />
        {selectedMeteredChargesTable === AggregateBillingPeriod.Hourly && (
          <MeteredChargesDatePicker
            billingPeriodType={selectedMeteredChargesTable}
            label="Reported Date"
            onChange={(dates) =>
              handleChargeDateChange(ChargeDateType.Reported, dates)
            }
            startDate={reportedDateStart}
            endDate={reportedDateEnd}
          />
        )}
        <FilterSelect
          ariaLabel="Products Filter"
          disabled={loading}
          multiple
          initialValue="Product Name"
          onChange={handleSelectProductFilters}
          onClear={handleResetFilters}
          options={createFilterOptions(eligibleProducts)}
          selection={selectedProducts}
        />
        <FilterSelect
          ariaLabel="Dimensions Filter"
          disabled={loading}
          multiple
          initialValue="Dimension"
          onChange={handleSelectDimensionsFilters}
          onClear={handleResetFilters}
          options={dimensionOptions}
          selection={selectedDimensions}
        />
      </FilterBar>
      <div className="ml-auto">
        <Tabs
          selectedTab={toProperCase(
            selectedMeteredChargesTable ||
              toProperCase(AggregateBillingPeriod.Hourly)
          )}
          onTabChange={handleTableChange}
          tabs={PERIOD_TABS || []}
        />
      </div>
    </div>
  );
};

const ExportButton = ({
  query,
  billingPeriodType,
}: {
  query: QueryParamsType;
  billingPeriodType: AggregateBillingPeriod;
}) => {
  const [triggerDownload, setTriggerDownload] = useState(false);

  let reportQuery;
  let variables = {};

  if (
    billingPeriodType === AggregateBillingPeriod.Daily ||
    billingPeriodType === AggregateBillingPeriod.Monthly
  ) {
    reportQuery = GET_AGGREGATED_METERED_CHARGES_REPORT;
    variables = {
      billingPeriodType,
      dimensionTypeIds: query.dimensionCodes,
    };
  } else {
    reportQuery = GET_METERED_CHARGES_TRANSACTIONS_REPORT;
    variables = {
      where: {
        ...(query?.products?.length && { catalogItemNameIn: query.products }),
        ...(query?.chargeDateStart && { chargeDateGte: query.chargeDateStart }),
        ...(query?.chargeDateEnd && { chargeDateLte: query.chargeDateEnd }),
        ...(query?.reportedDateStart && {
          reportedDateGte: query.reportedDateStart,
        }),
        ...(query?.reportedDateEnd && {
          reportedDateLte: query.reportedDateEnd,
        }),
        ...(query?.dimensionCodes?.length && {
          dimensionCodeIn: query.dimensionCodes,
        }),
      },
    };
  }

  const filename = `subscription-transactions-report-${billingPeriodType.toLowerCase()}.csv`;
  const [fetch, { loading }] = useCSVDownload({
    filename,
    query: reportQuery,
    variables,
    context: {},
  });

  const handleDownloadClick = () => {
    setTriggerDownload(true);
  };

  useEffect(() => {
    if (triggerDownload) {
      fetch();
      setTriggerDownload(false); // Reset after download to prevent repeated triggers
    }
  }, [triggerDownload, fetch]);

  return (
    <Button
      onClick={handleDownloadClick}
      size="small"
      loading={loading}
      className="mb-2 ml-auto mt-1 h-fit w-fit"
      kind="primary"
    >
      Export
    </Button>
  );
};

const TABLE_HEADERS = [
  { label: "Product Name", accessor: "productName" },
  { label: "Billing Period", accessor: "billingPeriod" },
  { label: "Dimension", accessor: "dimension" },
  {
    label: "Metered Units",
    accessor: "meteredUnits",
  },
  { label: "Reported Date", accessor: "reportedDate" },
  {
    label: "Charge Date",
    accessor: "chargeDate",
    sort: "CHARGE_DATE",
  },
];

export const AGGREGATED_TABLE_HEADERS = [
  { label: "Product Name", accessor: "productName" },
  { label: "Billing Period", accessor: "billingPeriod" },
  { label: "Dimension", accessor: "dimension" },
  {
    label: "Metered Units",
    accessor: "meteredUnits",
  },
];

const PERIOD_TABS = [
  AggregateBillingPeriod.Hourly,
  AggregateBillingPeriod.Daily,
  AggregateBillingPeriod.Monthly,
].map(toProperCase);
