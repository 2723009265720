import { RouteObject } from "react-router-dom";

import { PATHS } from "@mc/constants";
import { BillingSettingsPage } from "@mc/features/BillingSettings";
import { BookmarkSettingsPage } from "@mc/features/BookmarkSettings";
import { NotificationSettingsPage } from "@mc/features/NotificationSettings";
import { UserProfilePage } from "@mc/features/ProfileSettings";
import { SecuritySettingsPage } from "@mc/features/SecuritySettings";
import { MarketplaceSubscriptionsPage } from "@mc/features/SubscriptionSettings";
import { UserManagementPage } from "@mc/features/UserSettings";
import { NotFound } from "@mc/pages/404";
import { Navigate } from "@mc/router";

import { accountsRoutes } from "./accountsRoutes";

export const settingsRoutes: RouteObject[] = [
  {
    index: true,
    element: <Navigate replace to={PATHS.SETTINGS_PROFILE} />,
  },
  {
    path: "profile",
    element: <UserProfilePage />,
  },
  {
    path: "users",
    element: <UserManagementPage />,
  },
  {
    path: "accounts",
    children: accountsRoutes,
  },
  {
    path: "notifications",
    element: <NotificationSettingsPage />,
  },
  {
    path: "security",
    element: <SecuritySettingsPage />,
  },
  {
    path: "billing",
    element: <BillingSettingsPage />,
  },
  {
    path: "bookmarks",
    element: <BookmarkSettingsPage />,
  },
  {
    path: "subscriptions",
    element: <MarketplaceSubscriptionsPage />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];
