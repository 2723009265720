interface BookmarkFaviconProps {
  url: string;
  size?: number;
}

export const BookmarkFavicon = ({ url, size = 16 }: BookmarkFaviconProps) => {
  return (
    <img
      src={`https://www.google.com/s2/favicons?domain=${url}&sz=${size}`}
      alt={`${url} favicon`}
      style={{ width: size, height: size }}
      className="rounded border border-layout-border bg-layout-panel"
    />
  );
};
