export const ITEMS_PER_PAGE = 25;

interface Pagination {
  after: string;
  before: string;
  first: number;
  last: number;
}

export const INITIAL_PAGINATION: Pagination = {
  after: null,
  before: null,
  first: ITEMS_PER_PAGE,
  last: null,
};
