import { Outlet } from "react-router-dom";

import { useAuth } from "@m/login";

import { PATHS } from "@mc/constants";
import { useMarketplaceRegistrations } from "@mc/features/Onboarding";
import { DefaultLayout } from "@mc/layouts";
import { Navigate } from "@mc/router";

/**
 * Renders child routes, if the user has finished registration and onboarding.
 *
 * Redirects to registration routes, if the user hasn't finished registration.
 * Redirects to onboarding routes, if the user hasn't finished onboarding.
 */
export const DefaultOutlet = () => {
  const { user } = useAuth();
  const { hasStartedRegistrations } = useMarketplaceRegistrations();

  const forceRegistration = user?.isPendingInvite;
  const forceOnboarding = hasStartedRegistrations;

  if (forceRegistration) return <Navigate replace to={PATHS.REGISTER} />;
  if (forceOnboarding) return <Navigate replace to={PATHS.ONBOARDING} />;

  return (
    <DefaultLayout>
      <Outlet />
    </DefaultLayout>
  );
};
