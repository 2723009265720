import { useMemo, useState } from "react";
import {
  ArrayParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import {
  FilterBar,
  FilterSelect,
  Search,
  createFilterOptions,
  useSearchTerm,
} from "@m/ui";
import { formatFullDate } from "@m/utils";

import { Page } from "@mc/components/Page";

import {
  useClassicLoadBalancerFilters,
  useClassicLoadBalancerSearchTerms,
  useClassicLoadBalancers,
} from "../api";
import {
  ResourceDetails,
  ResourceTagsBadge,
  ResourcesPageHeader,
  ResourcesTable,
} from "../components";
import {
  AWS_RESOURCE_STATUS_TAGS,
  CLB_LABEL,
  GET_RESOURCES_EMPTY,
  MISSION_MANAGED_FILTER_ICON,
  MISSION_MANAGED_FILTER_LABEL,
  MISSION_MANAGED_FILTER_OPTIONS,
  TABLE_HEADERS_CLB,
} from "../constants";
import { createAccountOptions } from "../utils";

export const ClassicLoadBalancersPage = () => {
  const [query, setQuery] = useQueryParams({
    missionManaged: StringParam,
    accounts: ArrayParam,
    regions: ArrayParam,
    search: StringParam,
    sort: withDefault(StringParam, "CREATE_TIME_DESC"),
  });

  const {
    data: {
      filters: { accounts, regions },
    },
  } = useClassicLoadBalancerFilters();

  const {
    data: { searchTerms },
    refetch: refetchSearchTerms,
  } = useClassicLoadBalancerSearchTerms(query.search);

  const {
    searchTerm,
    handleSearchInputChange,
    handleSubmitSearch,
    clearSearchTerm,
  } = useSearchTerm({ setQuery, refetchSearchTerms, query });

  /** keeping the query for the resource last simplifies testing with calls.lastQuery */
  const {
    data: { classicLoadBalancers },
    loading,
    pagination,
  } = useClassicLoadBalancers(query);

  const [drawerDetails, setDrawerDetails] = useState<ResourceDetails | null>(
    null
  );

  const handleSortChange = (sort: string) => {
    pagination.setCurrentPage(1);
    setQuery({ sort });
  };

  const rows = useMemo(
    () =>
      classicLoadBalancers.map((clb) => {
        const {
          tags = "{}",
          name = "",
          createTime,
          monitoringPolicy = "",
          tagTotal,
        } = clb || {};

        const tagsData = JSON.parse(tags || "{}");
        const formattedTime = formatFullDate(createTime);
        const monitoringPolicyTag =
          AWS_RESOURCE_STATUS_TAGS[monitoringPolicy] || "";

        return {
          ...clb,
          tags: (
            <ResourceTagsBadge
              onClick={setDrawerDetails}
              details={{
                name,
                tags: tagsData,
              }}
              count={tagTotal}
            />
          ),
          monitoringPolicy: monitoringPolicyTag,
          createTime: formattedTime,
        };
      }),
    [classicLoadBalancers]
  );

  const handleClearFilters = () => {
    clearSearchTerm();

    setQuery({
      missionManaged: undefined,
      accounts: undefined,
      regions: undefined,
      search: undefined,
    });
  };

  const handleSelectManaged = (missionManaged: string) => {
    if (missionManaged === query.missionManaged) {
      setQuery({
        missionManaged: undefined,
      });
    } else {
      setQuery({
        missionManaged: missionManaged,
      });
    }
  };

  const handleDeselectManagedFilter = () => {
    setQuery({
      missionManaged: undefined,
    });
  };

  const handleSelectedAccountNames = (accountIds: string[]) => {
    setQuery({
      accounts: accountIds,
    });
  };

  const handleDeselectAccountsFilter = () => {
    setQuery({
      accounts: undefined,
    });
  };

  const handleSelectedRegions = (regions: string[]) => {
    setQuery({
      regions: regions,
    });
  };

  const handleDeselectRegionsFilter = () => {
    setQuery({
      regions: undefined,
    });
  };

  const hasActiveFilters =
    !!query.missionManaged ||
    query.accounts?.length > 0 ||
    query.regions?.length > 0 ||
    !!searchTerm;

  return (
    <Page width="full" title={<ResourcesPageHeader title={CLB_LABEL} />}>
      <FilterBar
        isActive={hasActiveFilters}
        onResetFilters={handleClearFilters}
        className="mb-2"
      >
        <Search
          ariaLabel="Classic Load Balancer Search Input"
          dropdownOptions={searchTerms}
          handleSearchInputChange={handleSearchInputChange}
          handleSubmitSearch={handleSubmitSearch}
          searchTerm={searchTerm}
        />
        <FilterSelect
          ariaLabel={MISSION_MANAGED_FILTER_LABEL}
          disabled={loading}
          icon={MISSION_MANAGED_FILTER_ICON}
          initialValue={MISSION_MANAGED_FILTER_OPTIONS.MANAGED}
          onChange={handleSelectManaged}
          onClear={handleDeselectManagedFilter}
          options={createFilterOptions(
            Object.values(MISSION_MANAGED_FILTER_OPTIONS)
          )}
          displayValue={query.missionManaged}
          selection={query.missionManaged || []}
        />
        <FilterSelect
          ariaLabel="Accounts Filter"
          disabled={accounts.length === 0 || loading}
          initialValue="Accounts"
          multiple={true}
          onChange={handleSelectedAccountNames}
          onClear={handleDeselectAccountsFilter}
          options={createAccountOptions(accounts)}
          selection={query.accounts || []}
        />
        <FilterSelect
          ariaLabel="Regions Filter"
          disabled={regions.length === 0 || loading}
          initialValue="Region"
          multiple={true}
          onChange={handleSelectedRegions}
          onClear={handleDeselectRegionsFilter}
          options={createFilterOptions(regions)}
          selection={query.regions || []}
        />
      </FilterBar>
      <ResourcesTable
        defaultSort={query.sort}
        drawerDetails={drawerDetails}
        emptyMessage={GET_RESOURCES_EMPTY(CLB_LABEL)}
        headers={TABLE_HEADERS_CLB}
        label={CLB_LABEL}
        loading={loading}
        onCloseDrawer={() => setDrawerDetails(null)}
        onSortChange={handleSortChange}
        pagination={pagination}
        rows={rows}
      />
    </Page>
  );
};
