import clsx from "clsx";
import { MouseEventHandler } from "react";

import { Button } from "@m/ui";

interface FilterBarProps {
  children: any;
  className?: string;
  isActive?: boolean;
  onResetFilters?: MouseEventHandler<HTMLButtonElement>;
}

export const FilterBar = ({
  children,
  className,
  isActive = false,
  onResetFilters,
}: FilterBarProps) => {
  const handleClickResetFilters: MouseEventHandler<HTMLButtonElement> = (e) => {
    onResetFilters?.(e);
  };

  return (
    <div
      className={clsx("flex flex-wrap items-center gap-2 p-1", className)}
      data-testid="filter-bar"
    >
      {children}
      {isActive && (
        <Button onClick={handleClickResetFilters} fill="none" size="small">
          Reset Filters
        </Button>
      )}
    </div>
  );
};
