import { useEffect, useState } from "react";

import { MarketplaceRegistrationStatus } from "@m/api/public/types";

import { SIGNUP_TOKEN_VALIDATION_URL } from "../constants";

export const useValidateRegistrationToken = (token: string) => {
  const [data, setData] = useState<ValidateRegistrationTokenData>(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    fetch(SIGNUP_TOKEN_VALIDATION_URL + token, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data: ValidateRegistrationTokenResponse) => {
        setData({
          companyId: data.company?.id,
          companyName: data.company?.name,
          productName: data.product_name,
          registrationStatus: data.registration_status,
          skipUserAccountSignup: data.skip_user_account_signup,
          success: data.success,
        });
        setError(data.error_message);
      })
      .catch((reason) => {
        setError(reason);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [token]);

  return { data, loading, error };
};

interface ValidateRegistrationTokenData {
  companyId: string;
  companyName: string;
  productName: string;
  registrationStatus: MarketplaceRegistrationStatus;
  skipUserAccountSignup: boolean;
  success: boolean;
}

export interface ValidateRegistrationTokenResponse {
  company: { name: string; id: string } | null;
  error_message: string | null;
  product_name: string | null;
  registration_status: MarketplaceRegistrationStatus | null;
  skip_user_account_signup: boolean | null;
  success: boolean;
}
