/**
 * Paths constant used to keep track of all of the path segments used throughout Mission Control.
 *
 * Relative paths are prefixed with an underscore (_).
 */
export const PATHS = {
  // Cloud Score
  CLOUD_SCORE: "/cloud-score",
  CLOUD_SCORE_PILLAR_DETAILS: "/cloud-score/pillars/:pillarId",

  // Cloud Score Overlays
  _CLOUD_SCORE_CHECK_DETAILS: "checks/:checkId",
  _CLOUD_SCORE_CHECK_DETAILS_TABNAME: "checks/:checkId/:tabName",
  _CLOUD_SCORE_CREATE_RECOMMENDATION: "recommendations/add/:checkId",
  _CLOUD_SCORE_RECOMMENDATION_DETAILS: "recommendations/:recommendationId",
  _CLOUD_SCORE_RECOMMENDATION_DETAILS_TABNAME:
    "recommendations/:recommendationId/:tabName",
  _CLOUD_SCORE_UPDATE_RECOMMENDATION: "recommendations/:recommendationId/edit",

  // Dashboard
  DASHBOARD: "/dashboard",
  DASHBOARD_CASES: "/dashboard/cases",
  DASHBOARD_CASES_CREATE: "/dashboard/cases/create",
  DASHBOARD_CASE_DETAILS: "/dashboard/cases/:sysId",

  // Engagements Engineer Assist
  ENGAGEMENTS_ENGINEER_ASSIST: "/engagements/engineer-assist",
  ENGAGEMENTS_ENGINEER_ASSIST_CREATE: "/engagements/engineer-assist/create",
  ENGAGEMENTS_ENGINEER_ASSIST_CREATE_REQUESTTYPE:
    "/engagements/engineer-assist/create/:requestType",
  ENGAGEMENTS_ENGINEER_ASSIST_DETAILS: "/engagements/engineer-assist/:sysId",

  // Engagements Projects
  ENGAGEMENTS_PROJECTS: "/engagements/projects",
  ENGAGEMENTS_PROJECTS_CONFIGURATION: "/engagements/projects/configuration",
  ENGAGEMENTS_PROJECTS_DETAILS: "/engagements/projects/:engagementId",
  ENGAGEMENTS_DEVOPS_PROJECTS_DETAILS: "projects/:engagementId",

  // Engagements Projects Drawers
  _ENGAGEMENT_CHECKLIST_ADD: "actionItems/add",
  _ENGAGEMENT_CHECKLIST_DETAILS: "actionItems/:actionItemId",
  _ENGAGEMENT_CHECKLIST_EDIT: "actionItems/:actionItemId/edit",
  _ENGAGEMENT_MILESTONE_ADD: "milestones/add",
  _ENGAGEMENT_MILESTONE_DETAILS: "milestones/:milestoneId",
  _ENGAGEMENT_MILESTONE_EDIT: "milestones/:milestoneId/edit",
  _ENGAGEMENT_RISK_LOG_DETAILS: "risks/:riskId",
  _ENGAGEMENT_RISK_LOG_ADD: "risks/add",
  _ENGAGEMENT_RISK_LOG_EDIT: "risks/:riskId/edit",
  _ENGAGEMENT_DECISION_DETAILS: "decisions/:decisionId",
  _ENGAGEMENT_DECISION_EDIT: "decisions/:decisionId/edit",
  _ENGAGEMENT_DECISION_ADD: "decisions/add",
  _ENGAGEMENT_SECTION_ACTION: ":section/:action",
  _ENGAGEMENT_STATUS_EDIT: "status/edit",
  _ENGAGEMENT_ACTIVITY_FEED: "activityFeed/view",

  // Logout
  LOGOUT: "/logout",

  // Onboarding
  ONBOARDING: "/onboarding",
  ONBOARDING_WELCOME: "/onboarding/welcome",
  ONBOARDING_ACCOUNT: "/onboarding/account",
  ONBOARDING_IDENTIFY_PAYER: "/onboarding/identify-payer",
  ONBOARDING_CONNECT_PAYER: "/onboarding/connect-payer",

  // Resources
  RESOURCES: "/resources",
  RESOURCES_ALB: "/resources/application-load-balancers",
  RESOURCES_ASG: "/resources/ec2-auto-scaling",
  RESOURCES_CLB: "/resources/classic-load-balancers",
  RESOURCES_EC2: "/resources/ec2",
  RESOURCES_ECS: "/resources/ecs",
  RESOURCES_EKS: "/resources/eks",
  RESOURCES_ELASTICACHE: "/resources/elasticache",
  RESOURCES_LAMBDA: "/resources/lambda",
  RESOURCES_NLB: "/resources/network-load-balancers",
  RESOURCES_RDS: "/resources/rds",
  RESOURCES_REDSHIFT: "/resources/redshift",

  // Registration
  REGISTER: "/register",

  // RIO Reporting
  RIO_REPORTING: "/rio",

  // Root
  ROOT: "/",

  // Scorecard (Redirects)
  SCORECARD: "/scorecard",
  SCORECARD_DETAILS: "/scorecard/:pillarId",

  // Settings
  SETTINGS: "/settings",
  SETTINGS_ACCOUNTS: "/settings/accounts",
  SETTINGS_ACCOUNTS_ADD: "/settings/accounts/add",
  SETTINGS_ACCOUNTS_CONNECT: "/settings/accounts/connect/:id",
  SETTINGS_BILLING: "/settings/billing",
  SETTINGS_BOOKMARKS: "/settings/bookmarks",
  SETTINGS_NOTIFICATIONS: "/settings/notifications",
  SETTINGS_PROFILE: "/settings/profile",
  SETTINGS_SECURITY: "/settings/security",
  SETTINGS_SUBSCRIPTIONS: "/settings/subscriptions",
  SETTINGS_SUBSCRIPTION_DETAILS:
    "/settings/subscriptions/:marketplaceProductCode",
  SETTINGS_USERS: "/settings/users",

  // Signup
  SIGNUP: "/signup",
  SIGNUP_ACCOUNT_EXISTS: "/signup/account-already-exists",
  SIGNUP_TOKEN: "/signup/:token",

  // Staff
  STAFF: "/staff",
  STAFF_GIT: "/staff/git",
  STAFF_GIT_ACCESS: "/staff/git/access",
  STAFF_GIT_CONNECTIONS: "/staff/git/connections",
  STAFF_INVITATIONS: "/staff/invitations",
  STAFF_REPORTS: "/staff/reports",
  STAFF_TEAM: "/staff/team",

  // Support (Customer Cases)
  SUPPORT: "/support",
  SUPPORT_CASES: "/support/cases",
  SUPPORT_CASES_CREATE: "/support/cases/create",
  SUPPORT_CASES_CREATE_CASETYPE: "/support/cases/create/:caseType",
  SUPPORT_CASE_DETAILS: "/support/cases/:sysId",
};

export const BASE_PATHS = Object.values(PATHS).reduce((basePaths, path) => {
  const basePath = path.split("/")[1];
  if (
    basePath === "" ||
    basePath.includes("*") ||
    basePath.includes(":") ||
    basePaths.includes(basePath)
  )
    return basePaths;

  return [...basePaths, basePath];
}, []);
