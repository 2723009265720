import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import {
  CheckIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useCallback, useEffect, useRef, useState } from "react";

import { ApolloQueryResult, generateUniqueId } from "@m/api";
import {
  CompanyBookmark,
  CompanyBookmarksQuery,
  UpdateBookmarkInput,
} from "@m/api/public/types";
import {
  Button,
  Confirm,
  Field,
  Input,
  Link,
  Spinner,
  SpinnerScreen,
} from "@m/ui";

import { AccessRequired } from "@mc/components/AccessRequired";
import { EmptyState } from "@mc/components/EmptyState";
import { Page } from "@mc/components/Page";
import { PageHeading } from "@mc/components/PageHeading";
import { MUTATIONS, PATHS } from "@mc/constants";

import { useCompanyBookmarks, useSetCompanyBookmarks } from "../api";
import { BookmarkFavicon } from "../components";
import { insertUrlScheme } from "../utils";

export const BookmarkSettingsPage = () => {
  const {
    data: { bookmarks: savedBookmarks },
    loading,
    refetch: refetchBookmarks,
  } = useCompanyBookmarks();

  const [newBookmarks, setNewBookmarks] = useState<LocalBookmark[]>([]);
  const [bookmarkToDelete, setBookmarkToDelete] =
    useState<CompanyBookmark | null>(null);

  const handleAddBookmark = () => {
    const newBookmark = {
      id: generateUniqueId(),
      linkDisplayName: "",
      linkShortDescription: "",
      url: "",
      isNew: true,
    } as LocalBookmark;
    setNewBookmarks((prev) => [...prev, newBookmark]);
  };

  const handleRemoveBookmark = (bookmark: LocalBookmark) => {
    if (bookmark.isNew)
      // If a new, unsaved bookmark is removed, we can skip the confirmation modal
      return setNewBookmarks((prev) =>
        prev.filter((addedBookmark) => addedBookmark.id !== bookmark.id)
      );

    setBookmarkToDelete(bookmark);
  };

  const bookmarks = [...savedBookmarks, ...newBookmarks];

  return (
    <Page data-testid="bookmark-settings-page">
      <PageHeading
        heading="Bookmarks"
        description={
          <>
            These links will be visible on the{" "}
            <Link to={PATHS.DASHBOARD}>Dashboard</Link> to all users in your
            company.
          </>
        }
        actions={
          <AccessRequired mutation={MUTATIONS.SET_COMPANY_BOOKMARKS}>
            <Button
              kind="primary"
              size="small"
              onClick={handleAddBookmark}
              disabled={newBookmarks.length > 0}
            >
              Add Bookmark
            </Button>
          </AccessRequired>
        }
      />

      {loading && <SpinnerScreen fitToParent />}

      {!loading && bookmarks.length === 0 && (
        <EmptyState
          title="No Bookmarks Available"
          description="Please ask your Mission Control admin to set up bookmarks."
        />
      )}

      {bookmarks.map((bookmark, index) => (
        <BookmarkForm
          bookmark={bookmark}
          index={index}
          key={bookmark.id}
          onRemove={handleRemoveBookmark}
          refetchBookmarks={refetchBookmarks}
          savedBookmarks={savedBookmarks}
        />
      ))}

      {bookmarkToDelete?.id && (
        <DeleteConfirmation
          bookmark={bookmarkToDelete}
          onClose={() => setBookmarkToDelete(null)}
          refetchBookmarks={refetchBookmarks}
          savedBookmarks={savedBookmarks}
        />
      )}
    </Page>
  );
};

const BookmarkForm = ({
  bookmark,
  index,
  onRemove,
  refetchBookmarks,
  savedBookmarks,
}: {
  bookmark: LocalBookmark;
  index: number;
  onRemove: (bookmark: LocalBookmark) => void;
  refetchBookmarks: () => Promise<ApolloQueryResult<CompanyBookmarksQuery>>;
  savedBookmarks: LocalBookmark[];
}) => {
  const formRef = useRef<HTMLFormElement>(null);

  const [setCompanyBookmarks, { data: bookmarksSaved, loading }] =
    useSetCompanyBookmarks();

  const [name, setName] = useState(bookmark.linkDisplayName);
  const [url, setUrl] = useState(bookmark.url);
  const [description, setDescription] = useState(bookmark.linkShortDescription);
  const [isEditMode, setIsEditMode] = useState(bookmark.isNew);

  const isInvalid = !name || !url;
  const isFirst = index === 0;
  const isLast = index === savedBookmarks.length - 1;

  const isMovementDisabled =
    isInvalid || isEditMode || loading || bookmark.isNew;
  const isMoveUpDisabled = isFirst || isMovementDisabled;
  const isMoveDownDisabled = isLast || isMovementDisabled;

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSaveBookmark();
  };

  const bookmarksToSave: UpdateBookmarkInput[] = savedBookmarks.map((b) => ({
    linkDisplayName: b.linkDisplayName,
    linkShortDescription: b.linkShortDescription,
    url: b.url,
  }));

  const handleSaveBookmark = () => {
    const bookmarkToSave: UpdateBookmarkInput = {
      linkDisplayName: name,
      linkShortDescription: description,
      url,
    };

    if (bookmark.isNew) bookmarksToSave.push(bookmarkToSave);
    else bookmarksToSave.splice(index, 1, bookmarkToSave);

    setCompanyBookmarks(bookmarksToSave);
  };

  const swapBookmarks = (fromIndex: number, toIndex: number) => {
    const updatedBookmarks = [...bookmarksToSave];
    const temp = updatedBookmarks[fromIndex];
    updatedBookmarks[fromIndex] = updatedBookmarks[toIndex];
    updatedBookmarks[toIndex] = temp;
    setCompanyBookmarks(updatedBookmarks);
  };

  const handleMoveUp = () => swapBookmarks(index, index - 1);
  const handleMoveDown = () => swapBookmarks(index, index + 1);

  const handleCancelEdit = useCallback(() => {
    setIsEditMode(false);
    if (bookmark.isNew) onRemove(bookmark);
  }, [bookmark, onRemove]);

  useEffect(() => {
    const form = formRef.current;

    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === "Escape" && !loading) handleCancelEdit();
    };

    form.addEventListener("keydown", handleEscape);
    return () => form.removeEventListener("keydown", handleEscape);
  }, [handleCancelEdit, loading]);

  useEffect(() => {
    if (bookmarksSaved) refetchBookmarks().then(handleCancelEdit);
  }, [bookmarksSaved, handleCancelEdit, bookmark, refetchBookmarks]);

  return (
    <form
      className="mb-2.5 flex items-center justify-between gap-2.5"
      data-testid="bookmark-form"
      key={bookmark.id}
      onSubmit={handleSubmit}
      ref={formRef}
    >
      <AccessRequired mutation={MUTATIONS.SET_COMPANY_BOOKMARKS}>
        <div className="flex items-center gap-1">
          <Link
            aria-label="Move Up"
            as="button"
            disabled={isMoveUpDisabled}
            fill="none"
            kind="secondary"
            onClick={handleMoveUp}
            size="small"
            className="h-fit"
          >
            <ArrowUpIcon className="h-2.5 w-2.5" />
          </Link>
          <Link
            aria-label="Move Down"
            as="button"
            disabled={isMoveDownDisabled}
            fill="none"
            kind="secondary"
            onClick={handleMoveDown}
            size="small"
            className="h-fit"
          >
            <ArrowDownIcon className="h-2.5 w-2.5" />
          </Link>
        </div>
      </AccessRequired>

      {isEditMode ? (
        <div className="grid grow grid-cols-3 gap-2.5">
          <Field label="Display Name">
            <Input
              autoFocus
              onChange={({ target: { value } }) => {
                setName(value);
              }}
              value={name}
            />
          </Field>

          <Field label="URL">
            <Input
              onChange={({ target: { value } }) => {
                setUrl(value);
              }}
              value={url}
            />
          </Field>

          <Field label="Description" flag="optional">
            <Input
              onChange={({ target: { value } }) => {
                setDescription(value);
              }}
              maxLength={MAX_DESCRIPTION_LENGTH}
              value={description}
            />
            <div className="flex justify-end text-xs text-subdued">
              {description.length}/{MAX_DESCRIPTION_LENGTH}
            </div>
          </Field>
        </div>
      ) : (
        <div
          className={clsx("grid grow grid-cols-3 items-center gap-2.5", {
            "pointer-events-none cursor-default opacity-60": loading,
          })}
        >
          <div className="flex items-center gap-1.5">
            <BookmarkFavicon url={bookmark.url} size={16} />
            <div className="font-semibold" aria-label="Display Name">
              {bookmark.linkDisplayName}
            </div>
          </div>

          <Link
            href={insertUrlScheme(bookmark.url)}
            aria-label="URL"
            target="_blank"
            className="hover:cursor-pointer hover:underline"
          >
            <div className="truncate">{bookmark.url}</div>
          </Link>

          <div className="text-right text-subdued" aria-label="Description">
            {bookmark.linkShortDescription}
          </div>
        </div>
      )}

      <div className="flex items-center gap-1">
        {loading && <Spinner className="h-2.5 w-2.5 text-subdued" />}

        {!loading && isEditMode && (
          <Link
            aria-label="Save Bookmark"
            as="button"
            disabled={isInvalid}
            fill="none"
            key={`save-bookmark-${bookmark.id}`}
            kind="primary"
            size="small"
            type="submit"
          >
            <CheckIcon className="h-2.5 w-2.5 text-status-good" />
          </Link>
        )}

        {!loading && !isEditMode && (
          <AccessRequired mutation={MUTATIONS.SET_COMPANY_BOOKMARKS}>
            <Link
              aria-label="Edit Bookmark"
              as="button"
              fill="none"
              key={`edit-bookmark-${bookmark.id}`}
              kind="primary"
              onClick={() => setIsEditMode(true)}
              size="small"
            >
              <PencilIcon className="h-2.5 w-2.5" />
            </Link>
          </AccessRequired>
        )}

        {isEditMode ? (
          <Link
            aria-label="Cancel Edit Bookmark"
            as="button"
            disabled={loading}
            fill="none"
            kind="secondary"
            onClick={handleCancelEdit}
            size="small"
          >
            <XMarkIcon className="h-2.5 w-2.5 text-status-error" />
          </Link>
        ) : (
          <AccessRequired mutation={MUTATIONS.SET_COMPANY_BOOKMARKS}>
            <Link
              aria-label="Delete Bookmark"
              as="button"
              disabled={loading}
              fill="none"
              size="small"
              kind="secondary"
              onClick={() => onRemove(bookmark)}
            >
              <TrashIcon className="h-2.5 w-2.5" />
            </Link>
          </AccessRequired>
        )}
      </div>
    </form>
  );
};

const DeleteConfirmation = ({
  bookmark,
  onClose,
  refetchBookmarks,
  savedBookmarks,
}: {
  bookmark: CompanyBookmark;
  onClose: () => void;
  refetchBookmarks: () => Promise<ApolloQueryResult<CompanyBookmarksQuery>>;
  savedBookmarks: CompanyBookmark[];
}) => {
  const [setCompanyBookmarks, { data: bookmarksSaved, loading }] =
    useSetCompanyBookmarks();

  const deleteBookmark = () => {
    const bookmarksToSave = savedBookmarks
      .filter((b) => b.id !== bookmark.id)
      .map((b) => ({
        linkDisplayName: b.linkDisplayName,
        linkShortDescription: b.linkShortDescription,
        url: b.url,
      }));
    setCompanyBookmarks(bookmarksToSave);
  };

  useEffect(() => {
    if (bookmarksSaved) refetchBookmarks().then(onClose);
  }, [bookmarksSaved, onClose, refetchBookmarks]);

  return (
    <Confirm
      onClose={onClose}
      aria-label="Delete Bookmark Dialog"
      open={!!bookmark.id}
      actions={[
        <Button
          key="delete-button"
          kind="primary"
          onClick={deleteBookmark}
          loading={loading}
        >
          Delete
        </Button>,
        <Button key="cancel-button" fill="none" onClick={onClose}>
          Cancel
        </Button>,
      ]}
    >
      <p>
        Are you sure you want to delete the{" "}
        <strong>{bookmark.linkDisplayName}</strong> link?
      </p>
      <p className="font-semibold">This action will apply for all users</p>
    </Confirm>
  );
};

const MAX_DESCRIPTION_LENGTH = 30;

type LocalBookmark = CompanyBookmark & { isNew?: boolean };
