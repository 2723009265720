import { useState } from "react";

import { gql } from "@m/api/public";
import { SwitchCompanyInput, SwitchCompanyMutation } from "@m/api/public/types";
import { useAuth } from "@m/login";

import { PATHS } from "@mc/constants";
import { useNavigate } from "@mc/router";
import { useMutation } from "@mc/utils/graphql";

export const SWITCH_COMPANY = gql(/* GraphQL */ `
  mutation SwitchCompany($input: SwitchCompanyInput!) {
    switchCompany(input: $input) {
      userProfile {
        currentCompany {
          id: databaseId
          name
          slug
          sso
          mfa
          sysId
        }
        roles
      }
    }
  }
`);

export const useSwitchCompany = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { user, updateUserSession } = useAuth();
  const navigate = useNavigate();

  const onCompleted = (data: SwitchCompanyMutation) => {
    const userProfile = data?.switchCompany?.userProfile;
    if (userProfile) {
      const { currentCompany, roles } = userProfile;
      updateUserSession({
        ...user,
        company: {
          id: currentCompany.id.toString(),
          name: currentCompany.name,
          slug: currentCompany.slug,
          sso: currentCompany.sso,
          mfa: currentCompany.mfa,
          sysId: currentCompany.sysId,
        },
        roles,
      });
    }
    setIsLoading(false);
  };

  /**
   * If we get an error, there's a permission error switching companies;
   * let's reload the page and get the latest code and state.
   */
  const onError = () => {
    navigate(PATHS.ROOT, { replace: true });
    setIsLoading(false);
  };

  const [mutate, { reset, ...result }] = useMutation(SWITCH_COMPANY, {
    onCompleted,
    onError,
  });

  const switchCompany = async (input: SwitchCompanyInput) => {
    setIsLoading(true);
    await mutate({ variables: { input } });
    reset();
  };

  /**
   * We're substituting our own loading state here to ensure
   * the hook remains in the 'loading' state until after the
   * user session is updated.
   */
  return [switchCompany, { ...result, loading: isLoading }] as const;
};
