import { useAuth } from "@m/login";
import { Button, Link } from "@m/ui";

import { CONTACT_US_URL, PATHS } from "@mc/constants";
import { ErrorPage } from "@mc/pages";

interface SubscriptionAlreadyConfiguredErrorProps {
  productName: string;
}

export const SubscriptionAlreadyConfiguredError = ({
  productName,
}: SubscriptionAlreadyConfiguredErrorProps) => {
  const { isAuthenticated } = useAuth();

  return (
    <ErrorPage
      title="Subscription Already Configured"
      content={
        <>
          <strong>{productName}</strong> has already been set up.{" "}
          {isAuthenticated ? (
            <>
              You can view your current subscriptions on your{" "}
              <Link to={PATHS.SETTINGS_SUBSCRIPTIONS}>
                Account Subscriptions
              </Link>{" "}
              page.
              <br />
              <br />
              <div className="text-base">
                If you need any further assistance, feel free to{" "}
                <Link to={CONTACT_US_URL}>reach out to us!</Link>
              </div>
            </>
          ) : (
            <>
              Log in to your Mission Control account to view your subscription
              details.
            </>
          )}
        </>
      }
      action={
        !isAuthenticated && (
          <Button kind="primary" to={PATHS.SETTINGS_SUBSCRIPTIONS}>
            Launch Mission Control
          </Button>
        )
      }
    />
  );
};
