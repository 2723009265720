import { gql } from "@m/api/public";

import { useMutation } from "@mc/utils/graphql";

export const ENFORCE_MFA = gql(/* GraphQL */ `
  mutation enforceMfa($input: MFAInput!) {
    enforceMfa(input: $input) {
      ok
      message
    }
  }
`);

export const useEnforceMfa = () => {
  const [mutate, { loading }] = useMutation(ENFORCE_MFA);

  const enforceMfa = (data) => {
    mutate({ variables: { input: data } });
  };

  return [enforceMfa, { loading }] as const;
};
