import { useQuery } from "@apollo/client";
import { createContext, useContext, useMemo } from "react";

import { gql } from "@m/api/public";
import {
  GetMarketplaceRegistrationsQuery,
  OnboardingRequestStatus,
} from "@m/api/public/types";
import { useAuth } from "@m/login";
import { SpinnerScreen } from "@m/ui";

import { SUPPORTED_PRODUCT_SKUS } from "@mc/constants";

interface MarketplaceRegistrations {
  /* New marketplace registrations that have not started onboarding */
  newRegistrations: MarketplaceRegistrationType[];
  hasNewRegistrations: boolean;

  /* Marketplace registrations that have started onboarding but are pending completion */
  startedRegistrations: MarketplaceRegistrationType[];
  hasStartedRegistrations: boolean;

  /* Marketplace registrations that have been successfully onboarded */
  onboardedRegistrations: MarketplaceRegistrationType[];
  hasOnboardedRegistrations: boolean;
}

export const MarketplaceRegistrationsContext =
  createContext<MarketplaceRegistrations>({
    newRegistrations: [],
    hasNewRegistrations: false,

    startedRegistrations: [],
    hasStartedRegistrations: false,

    onboardedRegistrations: [],
    hasOnboardedRegistrations: false,
  });

export const useMarketplaceRegistrations = () =>
  useContext(MarketplaceRegistrationsContext);

export const GET_MARKETPLACE_REGISTRATIONS = gql(/* GraphQL */ `
  query GetMarketplaceRegistrations($where: MarketplaceRegistrationFilter) {
    userProfile {
      currentCompany {
        marketplaceRegistrations(where: $where) {
          edges {
            node {
              awsAccountId
              catalogItem {
                id
                displayName
              }
              customerIdentifier
              databaseId
              expirationTime
              iamExternalId
              id
              linkedUsers {
                id
                email
              }
              marketplaceProductCode
              onboardingStatus
              payerAccountId
              registrationStatus
              sku
              signupToken
              subscribeTime
            }
          }
        }
      }
    }
  }
`);

export type MarketplaceRegistrationType =
  GetMarketplaceRegistrationsQuery["userProfile"]["currentCompany"]["marketplaceRegistrations"]["edges"][number]["node"];

export const MarketplaceRegistrationsProvider = ({ children }) => {
  const { data, loading } = useQuery(GET_MARKETPLACE_REGISTRATIONS);

  const { user } = useAuth();

  const registrations = useMemo(() => {
    const marketplaceRegistrations =
      data?.userProfile?.currentCompany?.marketplaceRegistrations?.edges.map(
        (edge) => edge.node
      ) || [];

    const supportedMarketplaceRegistrations = marketplaceRegistrations.filter(
      ({ sku }) => SUPPORTED_PRODUCT_SKUS.includes(sku)
    );

    const newRegistrations = supportedMarketplaceRegistrations.filter(
      ({ onboardingStatus }) =>
        onboardingStatus === OnboardingRequestStatus.NotStarted
    );

    const startedRegistrations = supportedMarketplaceRegistrations.filter(
      ({ onboardingStatus, linkedUsers }) =>
        onboardingStatus === OnboardingRequestStatus.Started &&
        linkedUsers.map(({ email }) => email).includes(user.email)
    );

    const onboardedRegistrations = supportedMarketplaceRegistrations.filter(
      ({ onboardingStatus }) => ONBOARDED_STATUSES.includes(onboardingStatus)
    );

    return {
      newRegistrations,
      hasNewRegistrations: newRegistrations.length > 0,

      startedRegistrations,
      hasStartedRegistrations: startedRegistrations.length > 0,

      onboardedRegistrations,
      hasOnboardedRegistrations: onboardedRegistrations.length > 0,
    };
  }, [data, user.email]);

  return (
    <MarketplaceRegistrationsContext.Provider value={registrations}>
      {loading ? (
        <SpinnerScreen data-testid="marketplace-registrations-provider" />
      ) : (
        children
      )}
    </MarketplaceRegistrationsContext.Provider>
  );
};

const ONBOARDED_STATUSES = [
  OnboardingRequestStatus.Complete,
  OnboardingRequestStatus.Processing,
];
