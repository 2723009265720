import { generatePath, useParams } from "react-router-dom";

import { MarketplaceRegistrationStatus } from "@m/api/public/types";
import { useAuth } from "@m/login";
import { SpinnerScreen } from "@m/ui";

import { Page } from "@mc/components/Page";
import { PATHS } from "@mc/constants";
import { Navigate } from "@mc/router";

import {
  MARKETPLACE_SIGNUP_ERRORS,
  useMarketplaceSignup,
  useValidateRegistrationToken,
} from "../api";
import {
  AccountCreationError,
  AccountSignIn,
  CompanyAlreadyExistsError,
  CreateAccountForm,
  CreateAccountFormElements,
  PreparingAccount,
  SubscriptionAlreadyConfiguredError,
  SubscriptionDoesNotExistError,
} from "../components";

export const MarketplaceSignupPage = () => (
  <Page data-testid="signup-page" padding={false}>
    <MarketplaceSignupPageContent />
  </Page>
);

const MarketplaceSignupPageContent = () => {
  const { token } = useParams<{ token: string }>();

  const { isAuthenticated } = useAuth();

  const {
    data: tokenValidationResponse,
    loading: tokenValidationLoading,
    error: tokenValidationError,
  } = useValidateRegistrationToken(token);

  const [
    signup,
    {
      data: signupResponse,
      loading: signupLoading,
      error: signupError,
      called: signupCalled,
    },
  ] = useMarketplaceSignup(token);

  const companyId = tokenValidationResponse?.companyId;
  const companyName = tokenValidationResponse?.companyName;
  const isTokenValid = tokenValidationResponse?.success;
  const productName = tokenValidationResponse?.productName;
  const registrationStatus = tokenValidationResponse?.registrationStatus;
  const skipUserAccountSignup = tokenValidationResponse?.skipUserAccountSignup;

  const redirectUrl = signupResponse?.auth0Url;

  const handleSubmit = (elements: CreateAccountFormElements) => {
    const emailAddress = elements.workEmailInput.value;
    const firstName = elements.firstNameInput.value;
    const lastName = elements.lastNameInput.value;

    if (companyId) {
      signup({ companyId, emailAddress, firstName, lastName });
    } else {
      const companyName = elements.companyNameInput.value;
      signup({ companyName, emailAddress, firstName, lastName });
    }
  };

  if (isAuthenticated) {
    const signupPath = generatePath(PATHS.SIGNUP_TOKEN, { token });
    return <Navigate replace to={signupPath} />;
  }

  if (registrationStatus === MarketplaceRegistrationStatus.Active) {
    return <SubscriptionAlreadyConfiguredError productName={productName} />;
  }

  if (tokenValidationLoading) {
    return <SpinnerScreen fitToParent />;
  }

  if (signupError === MARKETPLACE_SIGNUP_ERRORS.SIGNUP_ERROR) {
    return <AccountCreationError />;
  }
  if (signupError === MARKETPLACE_SIGNUP_ERRORS.DOMAIN_IN_USE) {
    return <CompanyAlreadyExistsError />;
  }
  if (tokenValidationError) {
    return <SubscriptionDoesNotExistError />;
  }

  const isAwaitingUserInput = isTokenValid && !signupCalled;

  if (isAwaitingUserInput && !skipUserAccountSignup) {
    return (
      <CreateAccountForm companyName={companyName} onSubmit={handleSubmit} />
    );
  }
  if (isAwaitingUserInput && skipUserAccountSignup) {
    return (
      <AccountSignIn
        companyName={companyName}
        companyId={companyId}
        token={token}
      />
    );
  }
  if (signupCalled) {
    return (
      <PreparingAccount loading={signupLoading} redirectUrl={redirectUrl} />
    );
  }
};
