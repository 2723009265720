import { useMutation } from "@apollo/client";

import { gql } from "@m/api/public";
import { UpdateBookmarkInput } from "@m/api/public/types";
import { toast } from "@m/ui";

export const SET_COMPANY_BOOKMARKS = gql(/* GraphQL */ `
  mutation SetCompanyBookmarks($input: SetCompanyBookmarksInput!) {
    setCompanyBookmarks(input: $input) {
      ok
      message
    }
  }
`);

export const useSetCompanyBookmarks = () => {
  const [mutate, { data, ...result }] = useMutation(SET_COMPANY_BOOKMARKS, {
    onError: () => toast.error("Unable to set company bookmarks"),
  });

  const setCompanyBookmarks = (bookmarks: UpdateBookmarkInput[]) => {
    return mutate({ variables: { input: { bookmarks } } });
  };
  const bookmarksSaved = data?.setCompanyBookmarks?.ok;

  return [setCompanyBookmarks, { data: bookmarksSaved, ...result }] as const;
};
