import { useMarketplaceRegistrations } from "../providers";

export const usePendingOnboarding = (sku?: string | string[]) => {
  const { startedRegistrations } = useMarketplaceRegistrations();

  if (Array.isArray(sku)) {
    for (const skuItem of sku) {
      const matchingOnboarding = startedRegistrations.find(
        (onboarding) => onboarding.sku === skuItem
      );
      if (matchingOnboarding) {
        return matchingOnboarding;
      }
    }
    return null;
  }

  if (sku)
    return (
      startedRegistrations.find((onboarding) => onboarding.sku === sku) || null
    );

  return startedRegistrations?.[0] || null;
};
