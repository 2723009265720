import { Float } from "@headlessui-float/react";
import { Combobox } from "@headlessui/react";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { KeyboardEvent, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@m/login";
import { Spinner } from "@m/ui";

import { useGetCompanies } from "@mc/api";
import { PATHS } from "@mc/constants";
import { generateCompanyPath } from "@mc/utils/companyPath";

interface CompanyOption {
  value: string;
  label: string;
  slug: string;
}

export function CompanySwitcher() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [selectedCompany, setSelectedCompany] = useState<CompanyOption>({
    value: user.company.id,
    label: user.company.name,
    slug: user.company.slug,
  });
  const [query, setQuery] = useState<string | null>(null);

  const {
    data: { companies },
    loading: isLoadingCompanies,
  } = useGetCompanies();

  const filteredCompanies = useMemo(() => {
    const options: Array<CompanyOption> = companies.map((company) => {
      return {
        value: company.id.toString(),
        label: company.name,
        slug: company.slug,
      };
    });

    return !query
      ? options
      : options.filter((option) =>
          option.label
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );
  }, [query, companies]);

  const handleFocus = () => {
    setSelectedCompany({
      value: undefined,
      label: undefined,
      slug: undefined,
    });
    setQuery(null);
  };

  const handleBlur = () =>
    setSelectedCompany({
      value: user.company.id,
      label: user.company.name,
      slug: user.company.slug,
    });

  const handleClick = () => {
    setQuery(null);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Escape" && query) setQuery(null);
    if (event.key === "Escape" && !query) event.currentTarget.blur();
  };

  useEffect(() => {
    if (selectedCompany.value && selectedCompany.value !== user.company.id) {
      navigate(
        generateCompanyPath(
          PATHS.ROOT,
          selectedCompany.slug || selectedCompany.value
        )
      );
    }
  }, [navigate, selectedCompany, user]);

  if (companies.length === 1) {
    return (
      <div className="grow cursor-default truncate text-sm font-bold">
        Mission Control
      </div>
    );
  }

  return (
    <Combobox value={selectedCompany} onChange={setSelectedCompany}>
      {({ open }) => (
        <Float offset={8} placement="bottom-start" portal>
          <Combobox.Button
            onClick={handleClick}
            className="inline-flex grow gap-x-1"
            data-testid="company-switcher"
          >
            <Combobox.Input
              displayValue={(company: CompanyOption) => company.label}
              placeholder="Search by company name"
              onChange={(e) => setQuery(e.target.value)}
              className="inline grow cursor-pointer truncate border-none bg-transparent p-0 text-sm font-bold leading-5 hover:opacity-80 focus:ring-0"
              onFocus={handleFocus}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
            />
            <div className="h-2.5 w-2.5 text-accent" aria-hidden="true">
              {open ? <ChevronDownIcon /> : <ChevronRightIcon />}
            </div>
          </Combobox.Button>
          <Combobox.Options
            className="dropdown-shadow overflow-auto rounded-md bg-white p-0.5 outline-none"
            style={{ width: 300, maxHeight: 300 }}
          >
            {isLoadingCompanies && (
              <div className="m-1 flex items-center justify-center">
                <Spinner />
              </div>
            )}
            {filteredCompanies.map((company: CompanyOption) => (
              <Combobox.Option
                key={company.value}
                value={company}
                className={({ active, selected }) =>
                  clsx(
                    "flex w-full cursor-pointer items-center rounded-md p-1 text-sm font-semibold text-default",
                    active && "bg-neutral-200 bg-opacity-[.6]",
                    selected && "bg-[#EDF3F8]"
                  )
                }
              >
                {company.label}
              </Combobox.Option>
            ))}
            {!isLoadingCompanies && filteredCompanies.length === 0 && (
              <div className="font-regular p-1 text-center text-sm font-semibold text-subdued">
                No companies found
              </div>
            )}
          </Combobox.Options>
        </Float>
      )}
    </Combobox>
  );
}
